/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // This includes Popper.js and jQuery


if (typeof window !== 'undefined') {
  import('jquery/dist/jquery.min.js');
  import('popper.js/dist/umd/popper.min.js');
  import('bootstrap/dist/js/bootstrap.min.js');
}

